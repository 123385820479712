var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v(" 360 Clash ")])]), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Reset Password ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Your new password must be different from previously used passwords ")]), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.left",
      value: 'Please use one uppercase letter, one lowercase letter, and one number.',
      expression: "\n          'Please use one uppercase letter, one lowercase letter, and one number.'\n        ",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "float-right mr-1",
    attrs: {
      "icon": "HelpCircleIcon",
      "size": "16"
    }
  }), _c('validation-observer', {
    ref: "simpleRules"
  }, [_c('b-form', {
    staticClass: "auth-reset-password-form mt-2",
    attrs: {
      "method": "POST"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.resetPassword($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "New Password",
      "label-for": "reset-password-new"
    }
  }, [_c('validation-provider', {
    attrs: {
      "vid": "password",
      "name": "Password",
      "rules": {
        required: true,
        regex: _vm.passwordRegex
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "reset-password-new",
            "type": _vm.passwordFieldType,
            "state": errors.length > 0 ? false : null,
            "name": "reset-password-new",
            "placeholder": "⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIcon
          },
          on: {
            "click": _vm.togglePasswordVisibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label-for": "reset-password-confirm",
      "label": "Confirm Password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Confirm Password",
      "rules": "required|confirmed:password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "reset-password-confirm",
            "type": _vm.repeatPasswordFieldType,
            "state": errors.length > 0 ? false : null,
            "name": "reset-password-confirm",
            "placeholder": "⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
          },
          model: {
            value: _vm.repeatPassword,
            callback: function callback($$v) {
              _vm.repeatPassword = $$v;
            },
            expression: "repeatPassword"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.repeatPasswordToggleIcon
          },
          on: {
            "click": _vm.toggleRepeatPasswordVisibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('spinning-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "block": "block",
      "is-loading": _vm.isLoading
    }
  }, [_vm._v(" Set New Password ")])], 1)], 1), _c('dismissible-message-box', {
    attrs: {
      "variant": _vm.MessageType.ERROR,
      "show": _vm.showErrorNotifications
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _c('dismissible-message-box', {
    attrs: {
      "variant": _vm.MessageType.SUCCESS,
      "show": _vm.showSuccessNotification
    }
  }, [_vm._v(" Successfully reset password. You can login now with new password now. ")]), _c('p', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Back to login ")], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }