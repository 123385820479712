<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            360 Clash
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Reset Password
        </b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previously used passwords
        </b-card-text>

        <!-- tooltip -->
        <feather-icon
          v-b-tooltip.hover.left="
            'Please use one uppercase letter, one lowercase letter, and one number.'
          "
          icon="HelpCircleIcon"
          size="16"
          class="float-right mr-1"
        />

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="resetPassword"
          >
            <!-- password -->
            <b-form-group
              label="New Password"
              label-for="reset-password-new"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="password"
                name="Password"
                :rules="{
                  required: true,
                  regex: passwordRegex
                }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- repeat password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="repeatPassword"
                    :type="repeatPasswordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="repeatPasswordToggleIcon"
                      @click="toggleRepeatPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <spinning-button
              variant="primary"
              type="submit"
              block="block"
              :is-loading="isLoading"
            >
              Set New Password
            </spinning-button>
          </b-form>
        </validation-observer>

        <dismissible-message-box
          :variant="MessageType.ERROR"
          :show="showErrorNotifications"
        >
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          {{ errors[0] }}
        </dismissible-message-box>

        <dismissible-message-box
          :variant="MessageType.SUCCESS"
          :show="showSuccessNotification"
        >
          Successfully reset password. You can login now with new password now.
        </dismissible-message-box>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" />
            Back to login
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import {
  togglePasswordVisibility, toggleRepeatPasswordVisibility,
} from '@core/mixins/ui/forms'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    SpinningButton,
    DismissibleMessageBox,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, toggleRepeatPasswordVisibility],
  data() {
    return {
      userEmail: this.$route.query.email,
      token: this.$route.query.token,
      password: '',
      repeatPassword: '',

      // validation
      required,
      //  1 uppercase letter, 1 lowercase letter, 1 number
      passwordRegex: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),

      // error notification box
      errors: [],
      showErrorNotifications: false,
      showSuccessNotification: false,

      // submit button
      isLoading: false,
    }
  },
  computed: {
    MessageType() {
      return MessageType
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    repeatPasswordToggleIcon() {
      return this.repeatPasswordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    async resetPassword() {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotification = false

      const formValid = await this.$refs.simpleRules.validate()
      if (!formValid) {
        return
      }

      const { success } = await this.$api.player.changePasswordWithToken(this.userEmail,
        this.token,
        this.password,
        this.repeatPassword)

      if (success === false) {
        this.errors = ['Something went wrong. Please try again later.']
        this.showErrorNotifications = true
        return
      }

      this.showSuccessNotification = true
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
